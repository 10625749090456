import React from 'react';
import styled from 'styled-components';
import { Text } from '@amzn/storm-ui';

const RegistrationPageTitleWrapper = styled(Text)`
  display: flex;
  justify-content: center;
  margin-block-end: ${({ theme }) => theme.spacing.xlarge};
`;
interface CreateAccountTitleProps {
  title: string;
}

const RegistrationTitle = ({ title }: CreateAccountTitleProps) => {
  return (
    <RegistrationPageTitleWrapper type="h1" fontSize="extraLarge">
      {title}
    </RegistrationPageTitleWrapper>
  );
};

export default RegistrationTitle;

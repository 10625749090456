import styled from 'styled-components';

export const RegistrationPageFooterWrapper = styled.div`
  display: flex;
  margin-block-end: ${({ theme }) => theme.spacing.large};
`;

export const RegistrationPageFooterText = styled.div`
  flex: 3;
`;

export const RegistrationPageFooterActions = styled.div`
  flex: 1;
  -webkit-box-pack: end;
  justify-content: flex-end;
  display: flex;
  align-items: baseline;
  button:not(:last-child) {
    margin-inline-end: ${({ theme }) => theme.spacing.medium};
  }
`;

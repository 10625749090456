import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Button } from '@amzn/storm-ui';
import useIntl from '@i18n/useIntl';
import ReadOnlyFormField from '@components/form/ReadOnlyFormRow';
import { toggleBusinessDetailsEditMode } from 'src/shared/state/account-settings-slice';
import * as businessDetailsConstants from '@features/account-settings/constants';
import { FORM_EDIT_BUTTON_TRANSLATION_KEY } from 'src/pages/accountSettings/constants';
import {
  ActionsButtonsWrapper,
  ControllersWrapper,
  ViewWrapper,
} from '@components/form/style';
import { BusinessInfo } from '@models/account';
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import BusinessAddressViewer from './BusinessAddressViewer';
import BusinessAddressDropdown from './BusinessAddressDropdown';
import { EM_DASH } from 'src/shared/constants';

interface BusinessDetailsViewerProps {
  displayEditButton?: boolean;
}

const BusinessDetailsViewer = ({
  displayEditButton = false,
}: BusinessDetailsViewerProps) => {
  const { intl } = useIntl();
  const dispatch = useDispatch();
  const { getValues, setValue, trigger } = useFormContext();

  const businessRegistrationNumber = getValues(
    businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_CONTROLLER_NAME,
  );

  const business = getValues(
    businessDetailsConstants.BUSINESS_DETAILS_CONTROLLER_GROUP,
  ) as BusinessInfo;

  useEffect(() => {
    if (business.addresses && isEqual(size(business.addresses), 1)) {
      const addressToken = get(business.addresses, [0, 'addressToken']);
      setValue(businessDetailsConstants.SELECTED_ADDRESS_ID, addressToken);
      trigger(businessDetailsConstants.SELECTED_ADDRESS_ID);
    }
  }, [business.addresses, setValue, trigger]);

  return (
    <ViewWrapper>
      <ControllersWrapper>
        <ReadOnlyFormField
          label={intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_NAME_FIELD_LABEL,
          )}
          id={businessDetailsConstants.BUSINESS_DETAILS_NAME_FIELD_ID}
          value={getValues(
            businessDetailsConstants.BUSINESS_DETAILS_NAME_FIELD_CONTROLLER_NAME,
          )}
        />
        {business.addresses && isEqual(size(business.addresses), 1) && (
          <ReadOnlyFormField
            label={intl.getMessage(
              businessDetailsConstants.BUSINESS_DETAILS_PHONE_FIELD_LABEL,
            )}
            id={businessDetailsConstants.BUSINESS_DETAILS_PHONE_FIELD_ID}
            value={get(business, ['addresses', 0, 'phoneNumber']) || EM_DASH}
          />
        )}
        <ReadOnlyFormField
          label={intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_WEBSITE_FIELD_LABEL,
          )}
          id={businessDetailsConstants.BUSINESS_DETAILS_WEBSITE_FIELD_ID}
          value={
            getValues(
              businessDetailsConstants.BUSINESS_DETAILS_WEBSITE_FIELD_CONTROLLER_NAME,
            ) || EM_DASH
          }
        />
        {business.addresses && isEqual(size(business.addresses), 1) && (
          <ReadOnlyFormField
            label={intl.getMessage(
              businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_FIELD_LABEL,
            )}
            id={businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_FIELD_ID}
          >
            <BusinessAddressViewer
              businessAddress={get(business, ['addresses', 0])}
            />
          </ReadOnlyFormField>
        )}
        {business.addresses && size(business.addresses) > 1 && (
          <BusinessAddressDropdown businessAddresses={business.addresses} />
        )}
        {businessRegistrationNumber && (
          <ReadOnlyFormField
            label={intl.getMessage(
              businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_LABEL,
            )}
            id={
              businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_ID
            }
            value={getValues(
              businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_CONTROLLER_NAME,
            )}
          />
        )}
      </ControllersWrapper>
      {displayEditButton && (
        <ActionsButtonsWrapper>
          <Button
            primary={false}
            onClick={() => dispatch(toggleBusinessDetailsEditMode())}
          >
            {intl.getMessage(FORM_EDIT_BUTTON_TRANSLATION_KEY)}
          </Button>
        </ActionsButtonsWrapper>
      )}
    </ViewWrapper>
  );
};

export default BusinessDetailsViewer;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FieldValues } from 'react-hook-form';
import { useCreateRetailerAccountMutation } from '@services/account/account';
import {
  setIsAccountCreationFailed,
  setIsAccountCreationLoading,
} from 'src/shared/state/registration-slice';
import useAccountStatus from '@features/account-registration/hooks/useAccountStatus';
import {
  CreateRetailerAccountPayload,
  CreateRetailerAccountResponse,
} from '@services/account/types';
import { CaptchaResponse } from '@models/captcha';
import { getValidatedState } from '@features/account-registration/utils';

const useSubmit = (invitationToken: string) => {
  const dispatch = useDispatch();
  const [createRetailerAccount, { isLoading, isError }] =
    useCreateRetailerAccountMutation();
  const { pollAccountStatus } = useAccountStatus(
    onAccountCreationSuccess,
    onAccountCreationFails,
  );

  /** Display the error banner or loading spinner in the page component **/
  useEffect(() => {
    if (isLoading) {
      dispatch(setIsAccountCreationLoading(true));
      dispatch(setIsAccountCreationFailed(false));
    }

    if (isError) {
      onAccountCreationFails();
    }
  }, [isLoading, isError, onAccountCreationFails, dispatch]);

  function onAccountCreationSuccess(url: string) {
    window.location.assign(url);
  }

  function onAccountCreationFails() {
    dispatch(setIsAccountCreationLoading(false));
    dispatch(setIsAccountCreationFailed(true));
  }

  const onSubmit = async (
    accountFields: FieldValues,
    captchaResponse?: CaptchaResponse,
  ) => {
    const payload = { ...accountFields } as CreateRetailerAccountPayload;

    if (payload.business?.state) {
      payload.business.state = getValidatedState(payload.business.state);
    }
    payload.invitationToken = invitationToken;

    try {
      const { globalAccountId }: CreateRetailerAccountResponse =
        await createRetailerAccount({
          data: payload,
          ...(captchaResponse && { captchaResponse }),
        }).unwrap();

      if (!globalAccountId) {
        onAccountCreationFails();
      }

      //Start calling account status API
      pollAccountStatus(globalAccountId);
    } catch (e) {
      throw new Error();
    }
  };

  return {
    onSubmit,
  };
};

export default useSubmit;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Button, Text } from '@amzn/storm-ui';
import useIntl from '@i18n/useIntl';
import ReadOnlyFormField from '@components/form/ReadOnlyFormRow';
import { toggleBusinessDetailsEditMode } from 'src/shared/state/account-settings-slice';
import * as businessDetailsConstants from '@features/account-settings/constants';
import { FORM_EDIT_BUTTON_TRANSLATION_KEY } from 'src/pages/accountSettings/constants';
import {
  ActionsButtonsWrapper,
  ControllersWrapper,
  TextWithSeparator,
  ViewWrapper,
} from '@components/form/style';

interface BusinessDetailsViewerProps {
  displayEditButton?: boolean;
}

const BusinessDetailsViewer = ({
  displayEditButton = false,
}: BusinessDetailsViewerProps) => {
  const { intl } = useIntl();
  const dispatch = useDispatch();
  const { getValues } = useFormContext();

  const optionalAddress = getValues(
    businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_LINE2_FIELD_CONTROLLER_NAME,
  );

  const businessRegistrationNumber = getValues(
    businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_CONTROLLER_NAME,
  );

  return (
    <ViewWrapper>
      <ControllersWrapper>
        <ReadOnlyFormField
          label={intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_NAME_FIELD_LABEL,
          )}
          id={businessDetailsConstants.BUSINESS_DETAILS_NAME_FIELD_ID}
          value={getValues(
            businessDetailsConstants.BUSINESS_DETAILS_NAME_FIELD_CONTROLLER_NAME,
          )}
        />
        <ReadOnlyFormField
          label={intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_PHONE_FIELD_LABEL,
          )}
          id={businessDetailsConstants.BUSINESS_DETAILS_PHONE_FIELD_ID}
          value={getValues(
            businessDetailsConstants.BUSINESS_DETAILS_PHONE_FIELD_CONTROLLER_NAME,
          )}
        />
        <ReadOnlyFormField
          label={intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_WEBSITE_FIELD_LABEL,
          )}
          id={businessDetailsConstants.BUSINESS_DETAILS_WEBSITE_FIELD_ID}
          value={getValues(
            businessDetailsConstants.BUSINESS_DETAILS_WEBSITE_FIELD_CONTROLLER_NAME,
          )}
        />
        <ReadOnlyFormField
          label={intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_FIELD_LABEL,
          )}
          id={businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_FIELD_ID}
        >
          <>
            <Text textColor="secondary">
              {getValues(
                businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_LINE1_FIELD_CONTROLLER_NAME,
              )}
            </Text>
            {optionalAddress && (
              <Text textColor="secondary">{optionalAddress}</Text>
            )}
            <>
              <TextWithSeparator type="span" textColor="secondary">
                {getValues(
                  businessDetailsConstants.BUSINESS_DETAILS_CITY_FIELD_CONTROLLER_NAME,
                )}
              </TextWithSeparator>
              <TextWithSeparator type="span" textColor="secondary">
                {getValues(
                  businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_CONTROLLER_NAME,
                )}
              </TextWithSeparator>
              <Text type="span" textColor="secondary">
                {getValues(
                  businessDetailsConstants.BUSINESS_DETAILS_POSTAL_CODE_FIELD_CONTROLLER_NAME,
                )}
              </Text>
            </>
            <Text textColor="secondary">
              {getValues(
                businessDetailsConstants.BUSINESS_DETAILS_COUNTRY_FIELD_CONTROLLER_NAME,
              )}
            </Text>
          </>
        </ReadOnlyFormField>
        {businessRegistrationNumber && (
          <ReadOnlyFormField
            label={intl.getMessage(
              businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_LABEL,
            )}
            id={
              businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_ID
            }
            value={getValues(
              businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_CONTROLLER_NAME,
            )}
          />
        )}
      </ControllersWrapper>
      {displayEditButton && (
        <ActionsButtonsWrapper>
          <Button
            primary={false}
            onClick={() => dispatch(toggleBusinessDetailsEditMode())}
          >
            {intl.getMessage(FORM_EDIT_BUTTON_TRANSLATION_KEY)}
          </Button>
        </ActionsButtonsWrapper>
      )}
    </ViewWrapper>
  );
};

export default BusinessDetailsViewer;

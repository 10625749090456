import styled from 'styled-components';
import { Icon, Text } from '@amzn/storm-ui';

export const RegistrationFormBusinessDetailsInfoText = styled(Text)`
  margin-block-end: ${({ theme }) => theme.spacing.base};
`;

export const RegistrationBusinessDetailsInfoWrapper = styled.div`
  display: flex;
  margin-block-start: ${({ theme }) => theme.spacing.base};
`;

export const BusinessDetailsInfoIcon = styled(Icon)`
  margin-inline-end: ${({ theme }) => theme.spacing.mini};
  color: ${({ theme }) => theme.palette.blue[700]};
`;

import React, { ReactNode } from 'react';
import { MessageBundle } from '@amzn/arb-tools';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { DynamicText } from '@components/DynamicText';
import { Link, Text } from '@amzn/storm-ui';
import { ACCOUNT_OVERVIEW_REDIRECT_URL } from './constants';
import * as accountRegistrationConst from '@features/account-registration/constants';

const ERROR_CODE_TO_ALERT_MAPPING = {
  INVALID_ADDRESS: {
    header:
      accountRegistrationConst.REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY,
    message:
      accountRegistrationConst.REGISTRATION_INVALID_ADDRESS_ERROR_BANNER_MESSAGE_TRANSLATION_KEY,
  },
  BUSINESS_NAME_TOO_LONG: {
    header:
      accountRegistrationConst.REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY,
    message:
      accountRegistrationConst.REGISTRATION_INVALID_BUSINESS_NAME_ERROR_BANNER_MESSAGE_TRANSLATION_KEY,
  },
  ACCOUNT_ALREADY_EXISTS_FOR_SELLING_ACCOUNT: {
    header:
      accountRegistrationConst.REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY,
    message:
      accountRegistrationConst.REGISTRATION_ACCOUNT_EXISTS_ERROR_BANNER_MESSAGE_TRANSLATION_KEY,
  },
  INVALID_INPUT: {
    header:
      accountRegistrationConst.REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY,
    message:
      accountRegistrationConst.REGISTRATION_INVALID_INPUT_ERROR_BANNER_MESSAGE_TRANSLATION_KEY,
  },
  INTERNAL_SERVICE_ERROR: {
    header:
      accountRegistrationConst.REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY,
    message:
      accountRegistrationConst.REGISTRATION_INTERNAL_SERVICE_ERROR_BANNER_MESSAGE_TRANSLATION_KEY,
  },
};

export const getErrorHeader = (
  intl: MessageBundle,
  errorCode: string,
): ReactNode => {
  const key = get(
    ERROR_CODE_TO_ALERT_MAPPING,
    [errorCode, 'header'],
    accountRegistrationConst.REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY,
  );

  return intl.getMessage(key);
};

export const getErrorMessage = (
  intl: MessageBundle,
  errorCode: string,
): ReactNode => {
  const key = get(
    ERROR_CODE_TO_ALERT_MAPPING,
    [errorCode, 'message'],
    accountRegistrationConst.REGISTRATION_INTERNAL_SERVICE_ERROR_BANNER_MESSAGE_TRANSLATION_KEY,
  );

  if (isEqual(errorCode, 'ACCOUNT_ALREADY_EXISTS_FOR_SELLING_ACCOUNT')) {
    return (
      <DynamicText
        message={intl.getMessage(key)}
        resolveComponent={() => (
          <Link href={ACCOUNT_OVERVIEW_REDIRECT_URL}>
            {intl.getMessage(
              accountRegistrationConst.REGISTRATION_ACCOUNT_LINK_HERE_TEXT,
            )}
          </Link>
        )}
      />
    );
  }

  return <Text>{intl.getMessage(key)}</Text>;
};

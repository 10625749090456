import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIntl from '@i18n/useIntl';
import useChrome from '@chrome/hooks/useChrome';
import { ChromeKeys } from '@chrome/enums';
import Spinner, { SpinnerSize } from '@components/Spinner';
import { useGetRetailerAccountRegistrationInfoQuery } from '@services/registration/registration';
import { RetailerAccountRegistrationInfoError } from '@services/registration/types';
import { RootState } from 'src/shared/state/store';
import { setIsRetailer } from 'src/shared/state/registration-slice';
import RetailerAccountRegistrationForm from 'src/pages/retailerAccountRegistration/components/RetailerAccountRegistrationForm';
import RetailerAccountRegistrationErrorBanner from './components/RetailerAccountRegistrationErrorBanner/RetailerAccountRegistrationErrorBanner';
import RegistrationUserDetails from '@features/account-registration/components/RegistrationUserDetails/RegistrationUserDetails';
import RegistrationGeneralErrorBanner from '@features/account-registration/components/RegistrationGeneralErrorBanner';
import { REGISTRATION_CREATE_ACCOUNT_SPINNER_MESSAGE_TRANSLATION_KEY } from '@features/account-registration/constants';
import {
  RETAILER_ACCOUNT_REGISTRATION_CREATE_BANNER_ID,
  RETAILER_ACCOUNT_REGISTRATION_PAGE_ID,
  RETAILER_ACCOUNT_REGISTRATION_TITLE_TRANSLATION_KEY,
} from 'src/pages/retailerAccountRegistration/constants';
import {
  RetailerRegistrationPageInnerWrapper,
  RetailerRegistrationPageWrapper,
} from 'src/pages/retailerAccountRegistration/style';
import { RegistrationFormCard } from '@features/account-registration/style';
import RegistrationTitle from '@features/account-registration/components/RegistrationTitle';

interface RetailerAccountRegistrationPageProps {
  invitationToken: string;
}

const RetailerAccountRegistrationPage = ({
  invitationToken,
}: RetailerAccountRegistrationPageProps) => {
  const { intl } = useIntl();
  const dispatch = useDispatch();
  useChrome(ChromeKeys.RetailerAccountRegistrationPage);

  const isAccountCreationLoading = useSelector(
    (state: RootState) => state.registration.isAccountCreationInProgress,
  );

  const isAccountCreationFailed = useSelector(
    (state: RootState) => state.registration.isAccountCreationFailed,
  );

  useEffect(() => {
    dispatch(setIsRetailer(true));
  }, []);

  const {
    data: retailerAccountRegistrationInfo,
    isLoading: isRetailerAccountRegistrationInfoLoading,
    error,
  } = useGetRetailerAccountRegistrationInfoQuery(invitationToken);

  if (isRetailerAccountRegistrationInfoLoading) {
    return <Spinner size={SpinnerSize.large} />;
  }

  /**
   * Show invitation error banner in case code exist otherwise show general error message.
   */
  if (error) {
    const { errorCode, data } =
      (error as RetailerAccountRegistrationInfoError).data || {};

    if (errorCode) {
      return (
        <RetailerAccountRegistrationErrorBanner
          errorCode={errorCode}
          loginEmail={data?.loginEmail}
        />
      );
    }

    return (
      <RegistrationGeneralErrorBanner
        id={RETAILER_ACCOUNT_REGISTRATION_PAGE_ID}
      />
    );
  }

  return (
    <RetailerRegistrationPageWrapper
      id={RETAILER_ACCOUNT_REGISTRATION_PAGE_ID}
      data-testid={RETAILER_ACCOUNT_REGISTRATION_PAGE_ID}
    >
      <RetailerRegistrationPageInnerWrapper>
        {isAccountCreationLoading && (
          <Spinner
            isFullScreen={true}
            text={intl.getMessage(
              REGISTRATION_CREATE_ACCOUNT_SPINNER_MESSAGE_TRANSLATION_KEY,
            )}
            size={SpinnerSize.large}
            withBackground={true}
          />
        )}
        {isAccountCreationFailed && (
          <RegistrationGeneralErrorBanner
            id={RETAILER_ACCOUNT_REGISTRATION_CREATE_BANNER_ID}
          />
        )}

        {retailerAccountRegistrationInfo && (
          <>
            <RegistrationTitle
              title={intl.formatMessage(
                RETAILER_ACCOUNT_REGISTRATION_TITLE_TRANSLATION_KEY,
                {
                  retailerName:
                    retailerAccountRegistrationInfo?.retailerName || '',
                },
              )}
            />
            <RegistrationFormCard>
              <RegistrationUserDetails
                userName={retailerAccountRegistrationInfo.userName}
                emailAddress={retailerAccountRegistrationInfo.emailAddress}
              />
            </RegistrationFormCard>
            <RetailerAccountRegistrationForm
              invitationToken={invitationToken}
              captcha={retailerAccountRegistrationInfo.captcha}
            />
          </>
        )}
      </RetailerRegistrationPageInnerWrapper>
    </RetailerRegistrationPageWrapper>
  );
};

export default RetailerAccountRegistrationPage;

/**
 * Registration translation keys
 */
export const REGISTRATION_FORM_TITLE_TRANSLATION_KEY =
  'registration_form_title';
export const REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY =
  'registration_generic_alert_banner_title';
export const REGISTRATION_GENERAL_ERROR_BANNER_MESSAGE_TRANSLATION_KEY =
  'registration_generic_alert_banner_message';
export const REGISTRATION_CREATE_ACCOUNT_SPINNER_MESSAGE_TRANSLATION_KEY =
  'registration_create_account_spinner_text';
export const REGISTRATION_INVALID_ADDRESS_ERROR_BANNER_MESSAGE_TRANSLATION_KEY =
  'registration_invalid_address';
export const REGISTRATION_INVALID_BUSINESS_NAME_ERROR_BANNER_MESSAGE_TRANSLATION_KEY =
  'registration_business_name_too_long';
export const REGISTRATION_ACCOUNT_EXISTS_ERROR_BANNER_MESSAGE_TRANSLATION_KEY =
  'registration_account_already_exists';
export const REGISTRATION_INVALID_INPUT_ERROR_BANNER_MESSAGE_TRANSLATION_KEY =
  'registration_invalid_input';
export const REGISTRATION_INTERNAL_SERVICE_ERROR_BANNER_MESSAGE_TRANSLATION_KEY =
  'registration_internal_service_error';
export const REGISTRATION_ACCOUNT_LINK_HERE_TEXT =
  'registration_account_link_here';

/**
 * General
 */
export const defaultFormValues = {
  displayName: '',
  business: {
    businessName: '',
    phone: '',
    website: '',
    countryCode: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
  },
};

export const POLLING_INTERVAL = 1000;
export const POLLING_INTERVAL_END = 30000;
export const CREATE_ACCOUNT_FAILED_ALERT_DURATION = 20000;

/**
 * Error message (internal)
 */
export const NO_URL_FOUND_ERROR = 'No URL found!';
export const ACCOUNT_TYPE_MISMATCH_ERROR =
  'Type mismatch detected. Please verify the account status type is correct.';

export const CREATE_ACCOUNT_FAILURE = 'create-account-failed';
export const CREATE_ACCOUNT_ID_MISSING = 'create-account-id-missing';
export const GET_ACCOUNT_STATUS_FAILURE = 'get-account-status-failed';
export const GET_ACCOUNT_STATUS_URL_MISSING = 'get-acount-status-url-missing';
export const GET_ACCOUNT_STATUS_DISABLED = 'get-acount-status-disabled';
export const GET_ACCOUNT_STATUS_TYPE_MISMATCH =
  'get-acount-status-type-mismatch';
export const ACCOUNT_CREATION_STATUS_TIMEOUT = 'create-account-status-timeout';

import styled from 'styled-components';
import { Card, Divider } from '@amzn/storm-ui';

export const RegistrationFormWrapper = styled.form`
  width: 100%;
`;

export const RegistrationFormCard = styled(Card)`
  margin-block-end: ${({ theme }) => theme.spacing.large};
`;

export const RegistrationCardContent = styled.div`
  padding: ${({ theme }) => `0 0 ${theme.spacing.large} 0`};
  :last-child {
    padding: ${({ theme }) => `${theme.spacing.large} 0 0 0`};
  }
`;

export const RegistrationCustomDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing.small} 0};
`;

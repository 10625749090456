/**
 * Registration / Account-Settings controllers labels, field names etc.
 */

/**
 * Account Details
 */

/**
 * Selectors
 */
export const ACCOUNT_NAME_FIELD_ID = 'account-name';
export const ACCOUNT_INDUSTRY_FIELD_ID = 'account-industry';
export const ACCOUNT_TIME_ZONE_FIELD_ID = 'account-time-zone';
export const ACCOUNT_CURRENCY_FIELD_ID = 'account-currency';
export const ACCOUNT_COUNTRY_CODES_FIELD_ID = 'country-codes';
export const BUSINESS_DETAILS_NAME_FIELD_ID = 'business-name';
export const BUSINESS_DETAILS_PHONE_FIELD_ID = 'business-phone';
export const BUSINESS_DETAILS_ADDRESS_FIELD_ID = 'business-address';
export const BUSINESS_DETAILS_WEBSITE_FIELD_ID = 'business-website';
export const BUSINESS_DETAILS_ADDRESS_LINE1_FIELD_ID = 'business-address-line1';
export const BUSINESS_DETAILS_COUNTRY_FIELD_ID = 'business-country';
export const BUSINESS_DETAILS_ADDRESS_LINE2_FIELD_ID = 'business-address-line2';
export const BUSINESS_DETAILS_POSTAL_CODE_FIELD_ID = 'business-postal-code';
export const BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_ID =
  'business-registration-number';
export const BUSINESS_DETAILS_CITY_FIELD_ID = 'business-city';
export const BUSINESS_DETAILS_STATE_FIELD_ID = 'business-state';

/**
 * Translation keys
 */
export const ACCOUNT_DISPLAY_NAME_FIELD_EDIT_LABEL =
  'account_info_form_account_display_name_label';
export const ACCOUNT_NAME_FIELD_EDIT_LABEL =
  'account_info_form_account_name_label';
export const ACCOUNT_INDUSTRY_FIELD_LABEL =
  'account_info_form_account_industry_label';
export const ACCOUNT_TIME_ZONE_FIELD_LABEL =
  'account_info_form_account_time_zone_label';
export const ACCOUNT_CURRENCY_FIELD_LABEL =
  'account_info_form_account_currency_label';
export const ACCOUNT_NAME_FIELD_TOOLTIP_TRANSLATION_KEY =
  'account_info_form_account_name_tooltip';
export const BUSINESS_DETAILS_NAME_FIELD_LABEL =
  'business_details_form_business_name_label';
export const BUSINESS_DETAILS_PHONE_FIELD_LABEL =
  'business_details_form_phone_number_label';
export const BUSINESS_DETAILS_WEBSITE_FIELD_LABEL =
  'business_details_form_website_label';
export const BUSINESS_DETAILS_ADDRESS_FIELD_LABEL =
  'account_settings_form_address_view_label';
export const BUSINESS_DETAILS_COUNTRY_FIELD_LABEL =
  'business_details_form_country_label';
export const BUSINESS_DETAILS_ADDRESS_LINE1_FIELD_LABEL =
  'business_details_form_address_line1_label';
export const BUSINESS_DETAILS_ADDRESS_LINE2_FIELD_LABEL =
  'business_details_form_address_line2_label';
export const BUSINESS_DETAILS_CITY_FIELD_LABEL =
  'business_details_form_city_label';
export const BUSINESS_DETAILS_STATE_FIELD_LABEL =
  'business_details_form_state_label';
export const BUSINESS_DETAILS_POSTAL_CODE_FIELD_LABEL =
  'business_details_form_postal_code_label';
export const BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_LABEL =
  'business_details_form_registration_number_label';
export const BUSINESS_DETAILS_NAME_FIELD_TOOLTIP_TRANSLATION_KEY =
  'business_details_form_business_name_tooltip';
export const BUSINESS_DETAILS_PHONE_FIELD_TOOLTIP_TRANSLATION_KEY =
  'business_details_form_phone_number_tooltip';
export const BUSINESS_DETAILS_WEBSITE_FIELD_TOOLTIP_TRANSLATION_KEY =
  'business_details_form_website_tooltip';
export const BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_TOOLTIP_TRANSLATION_KEY =
  'business_details_form_registration_number_tooltip';
export const SELLING_ACCOUNTS_VENDOR_TYPE = 'selling_accounts_vendor_type';
export const SELLING_ACCOUNTS_SELLER_TYPE = 'selling_accounts_seller_type';
export const SELLING_ACCOUNTS_AUTHOR_TYPE = 'selling_accounts_author_type';
export const BUSINESS_DETAILS_DROPDOWN_DEFAULT_LABEL_PREFIX =
  'business_details_form_address_dropdown_default_label';
export const BUSINESS_ADDRESS_DROPDOWN_DEFAULT_LABEL =
  'business_form_address_dropdown_default_label';
export const REGISTRATION_DROPDOWN_DEFAULT_LABEL_PREFIX =
  'registration_dropdown_default_label_prefix';
/**
 * Controllers
 */
export const ACCOUNT_NAME_FIELD_CONTROLLER_NAME = 'displayName';
export const ACCOUNT_INDUSTRY_FIELD_CONTROLLER_NAME = 'industry';
export const ACCOUNT_TIME_ZONE_FIELD_CONTROLLER_NAME = 'timeZone';
export const ACCOUNT_CURRENCY_FIELD_CONTROLLER_NAME = 'currency';
export const ACCOUNT_COUNTRY_CODES_FIELD_CONTROLLER_NAME = 'countries';
export const BUSINESS_DETAILS_CONTROLLER_GROUP = 'business';
export const BUSINESS_DETAILS_NAME_FIELD_CONTROLLER_NAME =
  'business.businessName';
export const BUSINESS_DETAILS_PHONE_FIELD_CONTROLLER_NAME = 'business.phone';
export const BUSINESS_DETAILS_WEBSITE_FIELD_CONTROLLER_NAME =
  'business.website';
export const BUSINESS_DETAILS_COUNTRY_FIELD_CONTROLLER_NAME =
  'business.countryCode';
export const BUSINESS_DETAILS_ADDRESS_LINE1_FIELD_CONTROLLER_NAME =
  'business.addressLine1';
export const BUSINESS_DETAILS_ADDRESS_LINE2_FIELD_CONTROLLER_NAME =
  'business.addressLine2';
export const BUSINESS_DETAILS_CITY_FIELD_CONTROLLER_NAME = 'business.city';
export const BUSINESS_DETAILS_STATE_FIELD_CONTROLLER_NAME = 'business.state';
export const BUSINESS_DETAILS_POSTAL_CODE_FIELD_CONTROLLER_NAME =
  'business.zipCode';
export const BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_CONTROLLER_NAME =
  'business.businessRegistrationNumber';

export const SELLING_ACCOUNTS_CONTROLLER_GROUP = 'sellingAccounts';
export const SELECTED_SELLING_ACCOUNT_ID = 'selectedSellingAccountId';
export const SELECTED_ADDRESS_ID = 'business.selectedAddressId';

import React, { ReactNode } from 'react';
import { Text } from '@amzn/storm-ui';
import isString from 'lodash/isString';
import styled from 'styled-components';

const EmptyAndErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

type EmptyAndErrorContainerProps = {
  title: ReactNode;
  message: ReactNode;
};

const EmptyAndErrorContainer = ({
  title,
  message,
}: EmptyAndErrorContainerProps) => {
  return (
    <EmptyAndErrorWrapper>
      <Text type="h5">{title}</Text>
      {isString(message) ? <Text>{message}</Text> : message}
    </EmptyAndErrorWrapper>
  );
};

export default EmptyAndErrorContainer;

import styled from 'styled-components';
import { Text } from '@amzn/storm-ui';

export const RegistrationSwitchEmailViewWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const RegistrationSwitchEmailViewCardWrapper = styled.div`
  max-width: 960px;
  width: 100%;
`;

export const RegistrationSwitchEmailViewCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    margin-block-end: ${({ theme }) => theme.spacing.base};
  }
`;

export const RegistrationSwitchEmailViewCardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const RegistrationSwitchEmailViewCardButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.base};
  flex-wrap: wrap;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing.xlarge} 0 0 0;
`;

export const TextWithMargin = styled(Text)`
  margin-inline-start: ${({ theme }) => theme.spacing.micro};
`;

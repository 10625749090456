import styled from 'styled-components';

export const SellingAccountsWrapper = styled.div`
  max-height: 480px;
`;

export const SellingAccountsTitleWrapper = styled.div`
  margin-block-end: ${({ theme }) => theme.spacing.small};
`;

export const SellingAccountTileCheckboxWrapper = styled.div`
  margin-block-end: ${({ theme }) => theme.spacing.small};
`;

export const SellingAccountPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-block-start: ${({ theme }) => theme.spacing.base};
  height: 31px;
`;

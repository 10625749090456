import { useEffect, useRef } from 'react';
import merge from 'lodash/merge';
import {
  initChrome,
  removeActionEventListeners,
  removeCommonEventListeners,
  removePivotEventListeners,
  removeSubsectionEventListeners,
  resetChrome,
  setBreadcrumbs,
  setHeader,
  setPageName,
  updateHeaderButtons,
} from '@chrome/api';
import {
  ChromeBreadcrumbItemConfig,
  ChromeHeaderButtonConfig,
  ChromeHeaderConfig,
} from '@chrome/types';
import useChromeConfiguration from '@chrome/hooks/useChromeConfiguration';
import { ChromeKeys } from '@chrome/enums';

const useChrome = (
  key: ChromeKeys,
  callback?: () => void,
  dependencies: unknown[] = [],
) => {
  const chromeConfiguration = useChromeConfiguration(key);
  const buttons = useRef(chromeConfiguration?.header?.buttons);
  const breadcrumbs = useRef(chromeConfiguration?.breadcrumbs);
  const subsectionsItems = useRef(
    chromeConfiguration?.header?.subsections?.items,
  );
  const sideNavigationItems = useRef(
    chromeConfiguration?.sideNavigation?.items,
  );

  /**
   * Update the current header configuration
   */
  const updateHeader = (props: Partial<ChromeHeaderConfig>) => {
    const header = chromeConfiguration?.header;
    if (header) {
      const updatedHeader = merge(header, props);
      setHeader(updatedHeader);
    }
  };

  /**
   * Update the current header buttons configuration
   */
  const updateButtons = (
    updatedHeaderButtons: Map<string, ChromeHeaderButtonConfig>,
  ) => {
    buttons.current = updatedHeaderButtons;
    updateHeaderButtons(updatedHeaderButtons);
  };

  /**
   * Update page name
   */
  const updatePageName = (pageName: string) => {
    setPageName(pageName);
  };

  /**
   * Update Breadcrumbs
   */
  const updateBreadcrumbs = (breadcrumbs: ChromeBreadcrumbItemConfig[]) => {
    setBreadcrumbs(breadcrumbs);
  };

  /**
   * Adds a new breadcrumb item to the end of the breadcrumbs list.
   */
  const addBreadcrumbs = (breadcrumb: ChromeBreadcrumbItemConfig) => {
    const updatedBreadcrumbs = [...(breadcrumbs.current || []), breadcrumb];
    setBreadcrumbs(updatedBreadcrumbs);
  };

  useEffect(() => {
    initChrome(chromeConfiguration);
    callback && callback();

    return () => {
      // Remove event listeners from the chrome header buttons
      buttons?.current &&
        removeActionEventListeners(Array.from(buttons.current.values()));
      // Remove event listeners from the chrome tabs
      subsectionsItems?.current &&
        removeSubsectionEventListeners(subsectionsItems.current);
      // Remove event listeners from the chrome side navigation items
      sideNavigationItems?.current &&
        removePivotEventListeners(sideNavigationItems.current);
      // Remove event listeners from the chrome header close button
      chromeConfiguration?.layout?.closeButtonCallback &&
        removeCommonEventListeners(
          chromeConfiguration.layout.closeButtonCallback,
        );

      resetChrome();
    };
  }, dependencies);

  return {
    updateHeader,
    updateButtons,
    updatePageName,
    updateBreadcrumbs,
    addBreadcrumbs,
    buttons: buttons.current,
  };
};

export default useChrome;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useIntl from '@i18n/useIntl';
import useChrome from '@chrome/hooks/useChrome';
import { ChromeKeys } from '@chrome/enums';
import { useGetRegistrationInfoQuery } from '@services/registration/registration';
import RegistrationForm from 'src/pages/registration/components/RegistrationForm';
import Spinner, { SpinnerSize } from '@components/Spinner';
import { RootState } from 'src/shared/state/store';
import RegistrationSwitchEmailView from 'src/pages/registration/components/RegistrationSwitchEmailView/RegistrationSwitchEmailView';
import RegistrationGeneralErrorBanner from '@features/account-registration/components/RegistrationGeneralErrorBanner';
import RegistrationTitle from '@features/account-registration/components/RegistrationTitle';
import { AlertMessage } from '@components/notifications/banner/Alerts';
import {
  ACCOUNT_REGISTRATION_PAGE_ID,
  REGISTRATION_TITLE_TRANSLATION_KEY,
} from 'src/pages/registration/constants';
import { REGISTRATION_CREATE_ACCOUNT_SPINNER_MESSAGE_TRANSLATION_KEY } from '@features/account-registration/constants';
import {
  RegistrationPageInnerWrapper,
  RegistrationPageWrapper,
} from 'src/pages/registration/style';

const RegistrationPage = () => {
  const { intl } = useIntl();
  useChrome(ChromeKeys.AccountRegistration);

  const isAccountCreationLoading = useSelector(
    (state: RootState) => state.registration.isAccountCreationInProgress,
  );

  const isAccountCreationFailed = useSelector(
    (state: RootState) => state.registration.isAccountCreationFailed,
  );

  const isOverrideBusinessEmail = useSelector(
    (state: RootState) => state.registration.isOverrideBusinessEmail,
  );

  const {
    data: registrationInfo,
    isLoading,
    isError,
  } = useGetRegistrationInfoQuery();

  useEffect(() => {
    if (isAccountCreationFailed) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isAccountCreationFailed]);

  if (isLoading) {
    return <Spinner size={SpinnerSize.large} />;
  }

  if (isError) {
    return <RegistrationGeneralErrorBanner id={ACCOUNT_REGISTRATION_PAGE_ID} />;
  }

  /**
   * In case it's not a business email we will show the RegistrationSwitchEmailView component
   */
  if (
    !isOverrideBusinessEmail &&
    registrationInfo &&
    !registrationInfo.businessEmail
  ) {
    return (
      <RegistrationSwitchEmailView
        email={registrationInfo.emailAddress}
        switchAccountURL={registrationInfo.switchAccountUrl}
      />
    );
  }

  return (
    <RegistrationPageWrapper
      id={ACCOUNT_REGISTRATION_PAGE_ID}
      data-testid={ACCOUNT_REGISTRATION_PAGE_ID}
    >
      <RegistrationPageInnerWrapper>
        {isAccountCreationLoading && (
          <Spinner
            isFullScreen={true}
            text={intl.getMessage(
              REGISTRATION_CREATE_ACCOUNT_SPINNER_MESSAGE_TRANSLATION_KEY,
            )}
            size={SpinnerSize.large}
            withBackground={true}
          />
        )}
        <AlertMessage enableScrollingIntoView />
        <RegistrationTitle
          title={intl.getMessage(REGISTRATION_TITLE_TRANSLATION_KEY)}
        />
        {registrationInfo && (
          <RegistrationForm
            userName={registrationInfo.userName}
            emailAddress={registrationInfo.emailAddress}
            switchAccountUrl={registrationInfo.switchAccountUrl}
            captcha={registrationInfo.captcha}
          />
        )}
      </RegistrationPageInnerWrapper>
    </RegistrationPageWrapper>
  );
};

export default RegistrationPage;

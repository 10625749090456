import React from 'react';
import { CardHeader, Text } from '@amzn/storm-ui';
import { FormProvider, useForm } from 'react-hook-form';
import useIntl from '@i18n/useIntl';
import { Captcha } from '@models/captcha';
import { handleKeyDown } from '@components/form/utils';
import useSubmit from 'src/pages/retailerAccountRegistration/hooks/useSubmit';
import {
  defaultFormValues,
  REGISTRATION_FORM_TITLE_TRANSLATION_KEY,
} from '@features/account-registration/constants';
import AccountDetailsControllers from '@features/account-settings/components/AccountDetailsControllers';
import RegistrationFormFooter from '@features/account-registration/components/RegistrationFooter/RegistrationFormFooter';
import BusinessDetails from '@features/account-registration/components/BusinessDetails/BusinessDetails';
import { RETAILER_ACCOUNT_REGISTRATION_PAGE_ID } from 'src/pages/retailerAccountRegistration/constants';
import {
  RegistrationCardContent,
  RegistrationCustomDivider,
  RegistrationFormCard,
  RegistrationFormWrapper,
} from '@features/account-registration/style';

interface RetailerAccountRegistrationFormProps {
  invitationToken: string;
  captcha: Partial<Captcha>;
}

const RetailerAccountRegistrationForm = ({
  invitationToken,
  captcha,
}: RetailerAccountRegistrationFormProps) => {
  const { intl } = useIntl();
  const { onSubmit } = useSubmit(invitationToken);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...defaultFormValues,
    },
  });

  return (
    <FormProvider {...methods}>
      <RegistrationFormWrapper onKeyDown={handleKeyDown}>
        <RegistrationFormCard>
          <CardHeader padding="large">
            <Text type="h2" fontSize="large">
              {intl.getMessage(REGISTRATION_FORM_TITLE_TRANSLATION_KEY)}
            </Text>
          </CardHeader>
          <BusinessDetails />
          <RegistrationCustomDivider />
          <RegistrationCardContent>
            <AccountDetailsControllers />
          </RegistrationCardContent>
        </RegistrationFormCard>
        <RegistrationFormFooter
          id={RETAILER_ACCOUNT_REGISTRATION_PAGE_ID}
          captcha={captcha}
          onSubmit={onSubmit}
        />
      </RegistrationFormWrapper>
    </FormProvider>
  );
};

export default RetailerAccountRegistrationForm;

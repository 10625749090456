import React from 'react';
import { useParams } from 'react-router-dom';
import { IntroWidgetAnchor } from '@amzn/intro-widgets-api';
import useIntl from '@i18n/useIntl';
import useChrome from '@chrome/hooks/useChrome';
import { ChromeKeys } from '@chrome/enums';
import { useGetAccountQuery } from '@services/account/account';
import Spinner from '@components/Spinner';
import { ErrorBanner } from '@components/notifications/banner/Banners';
import AccountDetails from 'src/pages/accountSettings/components/AccountDetails/AccountDetails';
import BusinessDetails from 'src/pages/accountSettings/components/BusinessDetails/BusinessDetails';
import {
  ACCOUNT_COUNTRY_CODES,
  ACCOUNT_SETTINGS_PAGE_ID,
  GENERAL_ERROR_BANNER_MESSAGE_KEY,
} from 'src/pages/accountSettings/constants';
import AccountDetailsSellingAccount from 'src/pages/accountSettings/components/AccountDetails/AccountDetailsSellingAccount';
import {
  AccountSettingsCard,
  AccountSettingsCardContent,
  AccountSettingsDivider,
} from 'src/pages/accountSettings/style';

const AccountSettingsPage = () => {
  useChrome(ChromeKeys.AccountSettings);

  const { intl } = useIntl();
  const { entityId } = useParams();

  const { data: account, isLoading, error } = useGetAccountQuery(entityId!);

  if (error) {
    return (
      <ErrorBanner
        id={ACCOUNT_SETTINGS_PAGE_ID}
        message={intl.getMessage(GENERAL_ERROR_BANNER_MESSAGE_KEY)}
        duration={0}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div id={ACCOUNT_SETTINGS_PAGE_ID} data-testid={ACCOUNT_SETTINGS_PAGE_ID}>
      <IntroWidgetAnchor id="account-settings-intro-banner" />
      {account && (
        <AccountSettingsCard padding="none">
          {account?.sellingAccounts && account.sellingAccounts.length > 0 && (
            <AccountSettingsCardContent>
              <AccountDetailsSellingAccount
                sellingAccounts={account?.sellingAccounts}
              />
            </AccountSettingsCardContent>
          )}
          <AccountSettingsDivider />
          <AccountSettingsCardContent>
            <BusinessDetails business={account?.business} />
          </AccountSettingsCardContent>
          <AccountSettingsDivider />
          <AccountSettingsCardContent>
            <AccountDetails
              displayName={account?.displayName}
              countries={ACCOUNT_COUNTRY_CODES}
            />
          </AccountSettingsCardContent>
        </AccountSettingsCard>
      )}
    </div>
  );
};

export default AccountSettingsPage;

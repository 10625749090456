import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FieldValues } from 'react-hook-form';
import { useCreateAccountMutation } from '@services/account/account';
import {
  setIsAccountCreationFailed,
  setIsAccountCreationLoading,
} from 'src/shared/state/registration-slice';
import useAccountStatus from '@features/account-registration/hooks/useAccountStatus';
import {
  CreateAccountResponse,
  CreateAccountPayload,
} from '@services/account/types';
import { CaptchaResponse } from '@models/captcha';
import { AccountStatus } from '@models/account';
import isEqual from 'lodash/isEqual';
import usePerformance from 'src/shared/hooks/usePerformance';
import { logEvent } from '@utils/takt';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { addAlert } from 'src/shared/state/alert-slice';
import useIntl from '@i18n/useIntl';
import { getErrorHeader, getErrorMessage } from 'src/pages/registration/utils';
import { getValidatedState } from '@features/account-registration/utils';
import { ACCOUNT_REGISTRATION_PAGE_ID } from 'src/pages/registration/constants';
import {
  CREATE_ACCOUNT_FAILED_ALERT_DURATION,
  CREATE_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_ID_MISSING,
} from '@features/account-registration/constants';

const useSubmit = () => {
  const dispatch = useDispatch();
  const { intl } = useIntl();
  const [createAccount, { isLoading, isError }] = useCreateAccountMutation();
  const { start, end, cancel } = usePerformance({
    key: 'AccountRegistrationTime',
  });

  const displayErrorAlert = (errorCode: string) => {
    const header = getErrorHeader(intl, errorCode);
    const message = getErrorMessage(intl, errorCode);

    dispatch(
      addAlert({
        id: CREATE_ACCOUNT_FAILURE,
        type: 'error',
        message,
        header,
        withCloseButton: true,
        duration: CREATE_ACCOUNT_FAILED_ALERT_DURATION,
      }),
    );
  };

  const onAccountCreationSuccess = (url: string, status: AccountStatus) => {
    isEqual(status, AccountStatus.Created) ? end() : cancel();
    window.location.assign(url);
  };

  const onAccountCreationFails = (reason: string) => {
    cancel();
    logEvent(`${ACCOUNT_REGISTRATION_PAGE_ID}-${CREATE_ACCOUNT_FAILURE}`, {
      reason,
    });
    displayErrorAlert(reason);
    dispatch(setIsAccountCreationLoading(false));
    dispatch(setIsAccountCreationFailed(true));
  };

  const { pollAccountStatus } = useAccountStatus(
    onAccountCreationSuccess,
    onAccountCreationFails,
  );

  /** Display the error banner or loading spinner in the page component **/
  useEffect(() => {
    if (isLoading) {
      dispatch(setIsAccountCreationLoading(true));
      dispatch(setIsAccountCreationFailed(false));
    }

    if (isError) {
      onAccountCreationFails(CREATE_ACCOUNT_FAILURE);
    }
  }, [isLoading, isError, onAccountCreationFails, dispatch]);

  const onSubmit = async (
    accountFields: FieldValues,
    captchaResponse?: CaptchaResponse,
  ) => {
    const payload = { ...accountFields } as CreateAccountPayload;
    try {
      start();

      if (payload.business?.state) {
        payload.business.state = getValidatedState(payload.business.state);
      }

      const { success, error }: CreateAccountResponse = await createAccount({
        data: payload,
        ...(captchaResponse && { captchaResponse }),
      }).unwrap();
      const advertiserAccountId = get(success, [
        0,
        'advertiserAccount',
        'advertiserAccountId',
      ]);

      const errorCode = get(error, [0, 'errors', 0, 'code'], null);

      if (!isNil(errorCode)) {
        onAccountCreationFails(errorCode);
        return;
      }

      if (!advertiserAccountId) {
        onAccountCreationFails(CREATE_ACCOUNT_ID_MISSING);
      }

      //Start calling account status API
      pollAccountStatus(advertiserAccountId);
    } catch (e) {
      throw new Error();
    }
  };

  return {
    onSubmit,
  };
};

export default useSubmit;

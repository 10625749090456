import styled from 'styled-components';

export const AdspRegistrationPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AdspRegistrationPageInnerWrapper = styled.div`
  max-width: 840px;
  width: 100%;
`;

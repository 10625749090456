/**
 * ADSP Registration page
 */

/**
 * Selectors
 */
export const ADSP_REGISTRATION_PAGE_ID = 'adsp-registration';
export const ADSP_REGISTRATION_CREATE_ACCOUNT_BANNER_ID = `${ADSP_REGISTRATION_PAGE_ID}-create-account`;

/**
 * translation keys
 */
export const ADSP_REGISTRATION_TITLE_TRANSLATION_KEY =
  'adsp_registration_title';

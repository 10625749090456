import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { CardHeader, Text } from '@amzn/storm-ui';
import useIntl from '@i18n/useIntl';
import { RootState } from 'src/shared/state/store';
import { Captcha } from '@models/captcha';
import { handleKeyDown } from '@components/form/utils';
import { getAccountCreationRequestFromFormData } from '@features/account-settings/utils';
import useSubmit from '@features/account-registration/hooks/useSubmit';
import RegistrationUserDetails from '@features/account-registration/components/RegistrationUserDetails/RegistrationUserDetails';
import SellingAccounts from '@features/account-registration/components/SellingAccounts/SellingAccounts';
import BusinessDetails from '@features/account-registration/components/BusinessDetails/BusinessDetails';
import AccountDetailsControllers from '@features/account-settings/components/AccountDetailsControllers';
import RegistrationFormFooter from '@features/account-registration/components/RegistrationFooter/RegistrationFormFooter';
import { ACCOUNT_DISPLAY_NAME_FIELD_EDIT_LABEL } from '@features/account-settings/constants';
import { ACCOUNT_REGISTRATION_PAGE_ID } from 'src/pages/registration/constants';
import {
  defaultFormValues,
  REGISTRATION_FORM_TITLE_TRANSLATION_KEY,
} from '@features/account-registration/constants';
import {
  RegistrationCardContent,
  RegistrationCustomDivider,
  RegistrationFormCard,
  RegistrationFormWrapper,
} from '@features/account-registration/style';

interface RegistrationFormProps {
  userName: string;
  switchAccountUrl: string;
  emailAddress: string;
  captcha: Partial<Captcha>;
}

const RegistrationForm = ({
  userName,
  emailAddress,
  switchAccountUrl,
  captcha,
}: RegistrationFormProps) => {
  const { intl } = useIntl();
  const { onSubmit } = useSubmit();

  // When the registration is non-endemic, we will use the captcha.
  // When the registration is endemic, the captcha will be disabled.
  const isNonEndemic = useSelector(
    (state: RootState) => state.registration.isNonEndemic,
  );

  const handleSubmit = useCallback(
    (data: FieldValues) => {
      const submitPayload = getAccountCreationRequestFromFormData(data);
      onSubmit(submitPayload);
    },
    [onSubmit],
  );

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...defaultFormValues,
      selectedSellingAccountId: null,
      business: {
        selectedAddressId: null,
        website: '',
        businessName: '',
        businessRegistrationNumber: '',
      },
      sellingAccounts: [],
    },
  });

  return (
    <FormProvider {...methods}>
      <RegistrationFormWrapper onKeyDown={handleKeyDown}>
        <RegistrationFormCard>
          <CardHeader padding="large">
            <RegistrationUserDetails
              userName={userName}
              emailAddress={emailAddress}
              switchAccountUrl={switchAccountUrl}
            />
          </CardHeader>
          <SellingAccounts
            pageId={ACCOUNT_REGISTRATION_PAGE_ID}
            switchAccountUrl={switchAccountUrl}
          />
        </RegistrationFormCard>
        <RegistrationFormCard>
          <CardHeader padding="large">
            <Text type="h2" fontSize="large">
              {intl.getMessage(REGISTRATION_FORM_TITLE_TRANSLATION_KEY)}
            </Text>
          </CardHeader>
          <BusinessDetails />
          <RegistrationCustomDivider />
          <RegistrationCardContent>
            <AccountDetailsControllers
              labelKey={ACCOUNT_DISPLAY_NAME_FIELD_EDIT_LABEL}
            />
          </RegistrationCardContent>
        </RegistrationFormCard>
      </RegistrationFormWrapper>
      <RegistrationFormFooter
        id={ACCOUNT_REGISTRATION_PAGE_ID}
        captcha={{
          ...captcha,
          captchaEnabled: captcha.captchaEnabled && isNonEndemic,
        }}
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
};

export default RegistrationForm;

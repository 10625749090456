/**
 * Selectors
 */
export const CANCEL_REGISTRATION_CONFIRM_MODAL_ID =
  'cancel-registration-confirm-modal';
export const SUBMIT_BUTTON = 'submit-button';
export const CANCEL_BUTTON = 'cancel-button';
export const CHALLENGE_IFRAME = 'challenge-iframe';
export const CAPTCHA_MODAL = 'captcha-modal';

/**
 * Translation keys
 */
export const REGISTRATION_FORM_FOOTER_MESSAGE_TRANSLATION_KEY =
  'registration_form_footer_message';
export const REGISTRATION_FORM_FOOTER_LINK_TRANSLATION_KEY =
  'registration_form_footer_link';
export const REGISTRATION_FORM_FOOTER_SUBMIT_BUTTON_TRANSLATION_KEY =
  'registration_form_footer_submit_button';
export const REGISTRATION_FORM_FOOTER_CANCEL_BUTTON_TRANSLATION_KEY =
  'registration_form_footer_cancel_button';
export const REGISTRATION_FORM_FOOTER_TOOLTIP_MESSAGE_TRANSLATION_KEY =
  'registration_form_footer_tooltip_message';
export const CANCEL_REGISTRATION_CONFIRMATION_MODAL_HEADER_TRANSLATION_KEY =
  'cancel_registration_confirm_modal_header';
export const CANCEL_REGISTRATION_CONFIRMATION_MODAL_MESSAGE_TRANSLATION_KEY =
  'cancel_registration_confirm_modal_message';
export const CANCEL_REGISTRATION_CONFIRMATION_MODAL_CONFIRM_BUTTON_TRANSLATION_KEY =
  'cancel_registration_confirm_modal_confirm_button_text';
export const CANCEL_REGISTRATION_CONFIRMATION_MODAL_CANCEL_BUTTON_TRANSLATION_KEY =
  'cancel_registration_confirm_modal_cancel_button_text';

/**
 * General
 */
export const TERMS_PAGE_URL = '/terms';

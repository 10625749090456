import { MessageBundle } from '@amzn/arb-tools';
import { ChromeMenuItemConfig } from '@chrome/types';
import { MenuItemIds } from '@chrome/enums';

/**
 * Chrome side-navigation items for access and settings pages
 */
export const getAccountSideNavigationItems = (
  intl: MessageBundle,
  entityId: string,
  navigate: (url: string) => void,
): ChromeMenuItemConfig[] => [
  {
    id: MenuItemIds.Account,
    label: intl.getMessage(MenuItemIds.Account),
    callback: entityId
      ? () => navigate(`/advertisingAccounts/${entityId}/account-settings`)
      : undefined,
    tag: 'new',
  },
  {
    id: MenuItemIds.Users,
    label: intl.getMessage(MenuItemIds.Users),
    callback: entityId
      ? () =>
          window.location.assign(
            `/am/managerAccounts/${entityId}/user/management`,
          )
      : undefined,
  },
  {
    id: MenuItemIds.ManagerAccount,
    label: intl.getMessage(MenuItemIds.ManagerAccount),
    callback: entityId
      ? () =>
          window.location.assign(
            `/advertisingAccounts/${entityId}/managerAccounts`,
          )
      : undefined,
  },
  {
    id: MenuItemIds.SellingAccount,
    label: intl.getMessage(MenuItemIds.SellingAccount),
    callback: entityId
      ? () =>
          window.location.assign(
            `/am/advertisingAccounts/${entityId}/retailPartners`,
          )
      : undefined,
  },
  {
    id: MenuItemIds.Brands,
    label: intl.getMessage(MenuItemIds.Brands),
    callback: entityId ? () => window.location.assign(`/brands`) : undefined,
  },
  {
    id: MenuItemIds.ThirdPartyApplication,
    label: intl.getMessage(MenuItemIds.ThirdPartyApplication),
    callback: entityId
      ? () => window.location.assign(`/am/thirdPartyApplications`)
      : undefined,
  },
  {
    id: MenuItemIds.DenyList,
    label: intl.getMessage(MenuItemIds.DenyList),
    callback: entityId ? () => window.location.assign(`/denylist`) : undefined,
  },
  {
    id: MenuItemIds.DataTransparency,
    label: intl.getMessage(MenuItemIds.DataTransparency),
    callback: entityId
      ? () =>
          window.location.assign(
            `/advertisingAccounts/${entityId}/data-transparency`,
          )
      : undefined,
  },
];

import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import useIntl from '@i18n/useIntl';
import InputController from '@components/form/InputController';
import DropdownController from '@components/form/DropdownController';
import * as businessDetailsConstants from '@features/account-settings/constants';
import * as formConstants from '@components/form/constants';
import { ControllersWrapper } from '@components/form/style';
import { useGetCountriesQuery, useGetStatesQuery } from '@services/address';
import { useSelector } from 'react-redux';
import { RootState } from 'src/shared/state/store';
import isEmpty from 'lodash/isEmpty';

const BusinessDetailsControllers = () => {
  const { intl } = useIntl();
  const { watch, setValue, unregister, register } = useFormContext();

  const isRetailer = useSelector(
    (state: RootState) => state.registration.isRetailer,
  );

  const countryCode = watch(
    businessDetailsConstants.BUSINESS_DETAILS_COUNTRY_FIELD_CONTROLLER_NAME,
    false,
  );

  const {
    data: countries,
    isLoading: isLoadingCountryList,
    isError: isLoadingCountryError,
  } = useGetCountriesQuery();

  const {
    data: states,
    isLoading: isLoadingStateList,
    isFetching: isFetchingStateList,
    isError: isLoadingStateError,
  } = useGetStatesQuery(countryCode, {
    skip: !countryCode,
  });

  const countryChangeHandler = () => {
    setValue(
      businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_CONTROLLER_NAME,
      '',
    );
  };

  const requiredValidation = useMemo(
    () => ({
      required: intl.getMessage(
        formConstants.REQUIRED_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY,
      ),
    }),
    [intl],
  );

  useEffect(() => {
    if (isLoadingStateError || isEmpty(states)) {
      unregister(
        businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_CONTROLLER_NAME,
      );
    } else {
      register(
        businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_CONTROLLER_NAME,
        { required: true },
      );
    }
  }, [isLoadingStateError, states]);

  return (
    <ControllersWrapper>
      <InputController
        label={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_NAME_FIELD_LABEL,
        )}
        id={businessDetailsConstants.BUSINESS_DETAILS_NAME_FIELD_ID}
        controllerName={
          businessDetailsConstants.BUSINESS_DETAILS_NAME_FIELD_CONTROLLER_NAME
        }
        registerOptions={{
          ...requiredValidation,
        }}
        helpTipText={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_NAME_FIELD_TOOLTIP_TRANSLATION_KEY,
        )}
      />
      <InputController
        label={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_PHONE_FIELD_LABEL,
        )}
        id={businessDetailsConstants.BUSINESS_DETAILS_PHONE_FIELD_ID}
        controllerName={
          businessDetailsConstants.BUSINESS_DETAILS_PHONE_FIELD_CONTROLLER_NAME
        }
        registerOptions={{
          ...requiredValidation,
          pattern: {
            value: formConstants.PHONE_VALIDATION,
            message: intl.getMessage(
              formConstants.PHONE_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY,
            ),
          },
        }}
        helpTipText={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_PHONE_FIELD_TOOLTIP_TRANSLATION_KEY,
        )}
        type="tel"
      />
      <InputController
        label={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_WEBSITE_FIELD_LABEL,
        )}
        id={businessDetailsConstants.BUSINESS_DETAILS_WEBSITE_FIELD_ID}
        controllerName={
          businessDetailsConstants.BUSINESS_DETAILS_WEBSITE_FIELD_CONTROLLER_NAME
        }
        registerOptions={{
          ...requiredValidation,
          pattern: {
            value: formConstants.WEBSITE_VALIDATION,
            message: intl.getMessage(
              formConstants.WEBSITE_URL_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY,
            ),
          },
        }}
        helpTipText={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_WEBSITE_FIELD_TOOLTIP_TRANSLATION_KEY,
        )}
      />
      <DropdownController
        label={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_COUNTRY_FIELD_LABEL,
        )}
        id={businessDetailsConstants.BUSINESS_DETAILS_COUNTRY_FIELD_ID}
        controllerName={
          businessDetailsConstants.BUSINESS_DETAILS_COUNTRY_FIELD_CONTROLLER_NAME
        }
        items={countries || []}
        registerOptions={{
          ...requiredValidation,
        }}
        overrideDropdownLabel={`${intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_DROPDOWN_DEFAULT_LABEL_PREFIX,
        )} 
        ${intl
          .getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_COUNTRY_FIELD_LABEL,
          )
          .toLocaleLowerCase()}
        `}
        isLoading={isLoadingCountryList}
        disabled={isLoadingCountryError}
        callback={countryChangeHandler}
      />
      <InputController
        label={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_LINE1_FIELD_LABEL,
        )}
        id={businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_LINE1_FIELD_ID}
        controllerName={
          businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_LINE1_FIELD_CONTROLLER_NAME
        }
        registerOptions={{
          ...requiredValidation,
        }}
      />
      <InputController
        label={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_LINE2_FIELD_LABEL,
        )}
        id={businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_LINE2_FIELD_ID}
        controllerName={
          businessDetailsConstants.BUSINESS_DETAILS_ADDRESS_LINE2_FIELD_CONTROLLER_NAME
        }
        optionalTag={intl.getMessage(
          formConstants.OPTIONAL_TAG_TRANSLATION_KEY,
        )}
      />
      <InputController
        label={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_CITY_FIELD_LABEL,
        )}
        id={businessDetailsConstants.BUSINESS_DETAILS_CITY_FIELD_ID}
        controllerName={
          businessDetailsConstants.BUSINESS_DETAILS_CITY_FIELD_CONTROLLER_NAME
        }
        registerOptions={{
          ...requiredValidation,
        }}
      />
      {!isLoadingStateError && !isEmpty(states) ? (
        <DropdownController
          label={intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_LABEL,
          )}
          id={businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_ID}
          controllerName={
            businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_CONTROLLER_NAME
          }
          items={states || []}
          registerOptions={{
            ...requiredValidation,
          }}
          overrideDropdownLabel={`${intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_DROPDOWN_DEFAULT_LABEL_PREFIX,
          )} 
        ${intl
          .getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_LABEL,
          )
          .toLocaleLowerCase()}`}
          isLoading={isLoadingStateList || isFetchingStateList}
          disabled={!states}
        />
      ) : (
        <InputController
          label={intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_LABEL,
          )}
          id={businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_ID}
          controllerName={
            businessDetailsConstants.BUSINESS_DETAILS_STATE_FIELD_CONTROLLER_NAME
          }
          optionalTag={intl.getMessage(
            formConstants.OPTIONAL_TAG_TRANSLATION_KEY,
          )}
        />
      )}
      <InputController
        label={intl.getMessage(
          businessDetailsConstants.BUSINESS_DETAILS_POSTAL_CODE_FIELD_LABEL,
        )}
        id={businessDetailsConstants.BUSINESS_DETAILS_POSTAL_CODE_FIELD_ID}
        controllerName={
          businessDetailsConstants.BUSINESS_DETAILS_POSTAL_CODE_FIELD_CONTROLLER_NAME
        }
        registerOptions={{
          ...requiredValidation,
          pattern: {
            value: formConstants.POSTAL_CODE_VALIDATION,
            message: intl.getMessage(
              formConstants.NUMBER_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY,
            ),
          },
        }}
      />

      {!isRetailer && (
        <InputController
          label={intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_LABEL,
          )}
          id={
            businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_ID
          }
          controllerName={
            businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_CONTROLLER_NAME
          }
          optionalTag={intl.getMessage(
            formConstants.OPTIONAL_TAG_TRANSLATION_KEY,
          )}
          registerOptions={{
            pattern: {
              value: formConstants.NUMBER_VALIDATION,
              message: intl.getMessage(
                formConstants.NUMBER_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY,
              ),
            },
          }}
          helpTipText={intl.getMessage(
            businessDetailsConstants.BUSINESS_DETAILS_REGISTRATION_NUMBER_FIELD_TOOLTIP_TRANSLATION_KEY,
          )}
        />
      )}
    </ControllersWrapper>
  );
};

export default BusinessDetailsControllers;

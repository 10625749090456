import React from 'react';
import { ErrorBanner } from '@components/notifications/banner/Banners';
import { Text } from '@amzn/storm-ui';
import {
  REGISTRATION_GENERAL_ERROR_BANNER_MESSAGE_TRANSLATION_KEY,
  REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY,
} from '@features/account-registration/constants';
import useIntl from '@i18n/useIntl';

interface RegistrationGeneralErrorBannerProps {
  id: string;
}

const RegistrationGeneralErrorBanner = ({
  id,
}: RegistrationGeneralErrorBannerProps) => {
  const { intl } = useIntl();
  return (
    <ErrorBanner id={id} duration={0}>
      <>
        <Text type="h4">
          {intl.getMessage(
            REGISTRATION_GENERAL_ERROR_BANNER_TITLE_TRANSLATION_KEY,
          )}
        </Text>
        <Text>
          {intl.getMessage(
            REGISTRATION_GENERAL_ERROR_BANNER_MESSAGE_TRANSLATION_KEY,
          )}
        </Text>
      </>
    </ErrorBanner>
  );
};

export default RegistrationGeneralErrorBanner;

import React, { useEffect } from 'react';
import { Text } from '@amzn/storm-ui';
import BusinessDetailsControllers from '@features/account-settings/components/BusinessDetailsControllers';
import BusinessDetailsViewer from './BusinessDetailsViewer';
import { useDispatch, useSelector } from 'react-redux';
import useIntl from '@i18n/useIntl';
import Spinner from '@components/Spinner';
import { createSelector } from '@reduxjs/toolkit';
import { addNotification } from 'src/shared/state/notifications-slice';
import { SnackbarTypes } from '@components/notifications/enums';
import { ACCOUNT_REGISTRATION_PAGE_ID } from 'src/pages/registration/constants';
import { BUSINESS_DETAILS_ERROR_STATE_MESSAGE_TRANSLATION_KEY } from '@features/account-registration/components/SellingAccounts/constants';
import {
  REGISTRATION_VIEWER_BUSINESS_DETAILS_BALANCE_MESSAGE_TRANSLATION_KEY,
  REGISTRATION_VIEWER_BUSINESS_DETAILS_MESSAGE_TRANSLATION_KEY,
} from '@features/account-registration/components/BusinessDetails/constants';
import { RegistrationCardContent } from '@features/account-registration/style';
import {
  BusinessDetailsInfoIcon,
  RegistrationBusinessDetailsInfoWrapper,
  RegistrationFormBusinessDetailsInfoText,
} from './style';

const BusinessDetails = () => {
  const { intl } = useIntl();
  const dispatch = useDispatch();
  const selectBusinessDetailsState = createSelector(
    [
      (state) => state.registration.isBusinessDetailsValid,
      (state) => state.registration.isBusinessDetailsLoading,
      (state) => state.registration.isBusinessDetailsFailed,
      (state) => state.registration.isSeller,
    ],
    (
      isBusinessDetailsValid,
      isBusinessDetailsLoading,
      isBusinessDetailsFailed,
      isSeller,
    ) => ({
      isBusinessDetailsValid,
      isBusinessDetailsLoading,
      isBusinessDetailsFailed,
      isSeller,
    }),
  );

  const {
    isBusinessDetailsValid,
    isBusinessDetailsLoading,
    isBusinessDetailsFailed,
    isSeller,
  } = useSelector(selectBusinessDetailsState);

  useEffect(() => {
    if (isBusinessDetailsFailed) {
      dispatch(
        addNotification({
          id: ACCOUNT_REGISTRATION_PAGE_ID,
          message: intl.getMessage(
            BUSINESS_DETAILS_ERROR_STATE_MESSAGE_TRANSLATION_KEY,
          ),
          type: SnackbarTypes.Error,
        }),
      );
    }
  }, [isBusinessDetailsFailed]);

  if (isBusinessDetailsLoading) {
    return <Spinner isFullScreen={false} />;
  }

  if (isBusinessDetailsValid) {
    return (
      <>
        <RegistrationFormBusinessDetailsInfoText>
          {intl.getMessage(
            REGISTRATION_VIEWER_BUSINESS_DETAILS_MESSAGE_TRANSLATION_KEY,
          )}
        </RegistrationFormBusinessDetailsInfoText>
        <BusinessDetailsViewer displayEditButton={false} />
        {isSeller && (
          <RegistrationBusinessDetailsInfoWrapper>
            <BusinessDetailsInfoIcon type="info-circle" aria-hidden="true" />
            <Text textColor="tertiary" fontSize="small">
              {intl.getMessage(
                REGISTRATION_VIEWER_BUSINESS_DETAILS_BALANCE_MESSAGE_TRANSLATION_KEY,
              )}
            </Text>
          </RegistrationBusinessDetailsInfoWrapper>
        )}
      </>
    );
  }

  return (
    <RegistrationCardContent>
      <RegistrationFormBusinessDetailsInfoText>
        {intl.getMessage(
          REGISTRATION_VIEWER_BUSINESS_DETAILS_MESSAGE_TRANSLATION_KEY,
        )}
      </RegistrationFormBusinessDetailsInfoText>
      <BusinessDetailsControllers />
    </RegistrationCardContent>
  );
};

export default BusinessDetails;

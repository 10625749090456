import { createApi } from '@reduxjs/toolkit/query/react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import axiosBaseQuery from 'src/shared/api/api';
import {
  Account,
  SellingAccountsResponse,
  SellingAccountType,
} from 'src/shared/models/account';
import { GetAccountStatusResponse } from '@services/account/types';
import {
  CreateAccountPayload,
  CreateAccountResponse,
  CreateRetailerAccountResponse,
  CreateRetailerAccountPayload,
} from '@services/account/types';
import { CaptchaResponse } from '@models/captcha';
import { SellingProgram } from '@features/account-settings/utils';

const BASE_URL = '/ads-account/api/v1/';
const ACCOUNT_ENDPOINT = 'account';
const RETAILER_ACCOUNT_ENDPOINT = 'retailer-account';
const ACCOUNT_STATUS_ENDPOINT = 'account-status';
const REDUCER_PATH = 'AccountApi';
const SELLING_ACCONTS = 'sellingAccounts';

const getCaptchaHeaders = ({
  sessionToken,
  actionType,
  clientSideContext,
}: CaptchaResponse) => {
  return {
    'Captcha-Session-Token': sessionToken,
    'Captcha-Action-Type': actionType,
    'Captcha-Client-Side-Context': clientSideContext,
  };
};

/**
 *  Account Service
 */
export const accountApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['Account', 'SellingAccount'],
  endpoints: (builder) => ({
    getAccount: builder.query<Account, string>({
      query: (entityId) => ({
        url: `${ACCOUNT_ENDPOINT}/${entityId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, entityId) => [
        { type: 'Account', id: entityId },
      ],
    }),
    getAccountStatus: builder.query<GetAccountStatusResponse, string>({
      query: (globalAccountId) => ({
        url: `${ACCOUNT_STATUS_ENDPOINT}/${globalAccountId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, globalAccountId) => [
        { type: 'Account', id: globalAccountId },
      ],
    }),
    updateAccount: builder.mutation<
      Account,
      { entityId: string; data: Partial<Account> }
    >({
      query: ({ entityId, data }) => ({
        url: `${ACCOUNT_ENDPOINT}/${entityId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { entityId }) => [
        { type: 'Account', id: entityId },
      ],
    }),
    createAccount: builder.mutation<
      CreateAccountResponse,
      { data: CreateAccountPayload; captchaResponse?: CaptchaResponse }
    >({
      query: ({ data, captchaResponse }) => ({
        url: `${ACCOUNT_ENDPOINT}`,
        method: 'POST',
        ...(captchaResponse && {
          headers: getCaptchaHeaders(captchaResponse),
        }),
        data,
      }),
    }),
    createRetailerAccount: builder.mutation<
      CreateRetailerAccountResponse,
      { data: CreateRetailerAccountPayload; captchaResponse?: CaptchaResponse }
    >({
      query: ({ data, captchaResponse }) => ({
        url: `${RETAILER_ACCOUNT_ENDPOINT}`,
        method: 'POST',
        ...(captchaResponse && {
          headers: getCaptchaHeaders(captchaResponse),
        }),
        data,
      }),
    }),
    querySellingAccount: builder.query<
      SellingAccountsResponse,
      { sellingAccountLinkToken?: string; sellingProgram?: SellingAccountType }
    >({
      query: ({ sellingAccountLinkToken, sellingProgram }) => {
        let url = SELLING_ACCONTS;
        if (sellingAccountLinkToken && sellingProgram) {
          url = `${url}?sellingAccountLinkToken=${sellingAccountLinkToken}&sellingProgram=${get(
            SellingProgram,
            [sellingProgram],
          )}`;
        }
        return {
          url,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
      providesTags: (result, error, { sellingAccountLinkToken }) => [
        {
          type: 'SellingAccount',
          id: sellingAccountLinkToken ? sellingAccountLinkToken : '',
        },
      ],
    }),
    // Added an example to demonstrate how multiple calls can be done parallely.
    // We can add api calls to the list of promises and check responses for each call to either return response or throw an error.
    // Currently it's not used anywhere.
    multipleQueries: builder.query<Account, string>({
      async queryFn(entityId, _queryApi, _extraOptions, fetchWithBaseQuery) {
        const [getAccountResult] = await Promise.all([
          fetchWithBaseQuery({
            url: `${ACCOUNT_ENDPOINT}/${entityId}`,
            method: 'GET',
          }),
        ]);

        if (!isEmpty(getAccountResult.error)) {
          throw new Error('Fetch account failed');
        }
        return {
          data: getAccountResult.data as Account,
        };
      },
    }),
  }),
});

export const {
  useGetAccountQuery,
  useGetAccountStatusQuery,
  useUpdateAccountMutation,
  useCreateAccountMutation,
  useCreateRetailerAccountMutation,
  useQuerySellingAccountQuery,
  useLazyQuerySellingAccountQuery,
  useMultipleQueriesQuery,
} = accountApi;

import React, { useEffect } from 'react';
import { Link, TextButton } from '@amzn/storm-ui';
import { useDispatch } from 'react-redux';
import SellingAccountsTiles from '@features/account-registration/components/SellingAccounts/SellingAccountTiles';
import SellingAccountsTitle from './SellingAccountsTitle';
import useGetSellingAccounts from '../../hooks/useGetSellingAccounts';
import EmptyAndErrorContainer from '@components/EmptyAndErrorContainer';
import Spinner from '@components/Spinner';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import useIntl from '@i18n/useIntl';
import {
  SELLING_ACCOUNT_EMPTY_STATE_MESSAGE_TRANSLATION_KEY,
  SELLING_ACCOUNT_EMPTY_STATE_TITLE_TRANSLATION_KEY,
  SELLING_ACCOUNT_ERROR_STATE_MESSAGE_TRANSLATION_KEY,
  SELLING_ACCOUNT_ERROR_STATE_TITLE_TRANSLATION_KEY,
} from './constants';
import { setIsNonEndemic } from 'src/shared/state/registration-slice';
import { SellingAccountsWrapper } from './style';

type SellingAccountsProps = {
  pageId: string;
  switchAccountUrl?: string;
};
const SellingAccounts = ({
  pageId,
  switchAccountUrl,
}: SellingAccountsProps) => {
  const { intl } = useIntl();
  const dispatch = useDispatch();

  const {
    sellingAccounts = [],
    countryList = [],
    isLoading,
    isError,
  } = useGetSellingAccounts();

  useEffect(() => {
    if (size(sellingAccounts) > 0) {
      dispatch(setIsNonEndemic(isEmpty(sellingAccounts)));
    }
  }, [dispatch, sellingAccounts]);

  if (isError) {
    return (
      <EmptyAndErrorContainer
        title={intl.getMessage(
          SELLING_ACCOUNT_ERROR_STATE_TITLE_TRANSLATION_KEY,
        )}
        message={intl.getMessage(
          SELLING_ACCOUNT_ERROR_STATE_MESSAGE_TRANSLATION_KEY,
        )}
      />
    );
  }

  if (isLoading) {
    return <Spinner isFullScreen={false} />;
  }

  return (
    <SellingAccountsWrapper>
      <SellingAccountsTitle />
      {isEmpty(sellingAccounts) ? (
        <EmptyAndErrorContainer
          title={intl.getMessage(
            SELLING_ACCOUNT_EMPTY_STATE_TITLE_TRANSLATION_KEY,
          )}
          message={
            switchAccountUrl ? (
              <TextButton>
                <Link href={switchAccountUrl}>
                  {intl.getMessage(
                    SELLING_ACCOUNT_EMPTY_STATE_MESSAGE_TRANSLATION_KEY,
                  )}
                </Link>
              </TextButton>
            ) : (
              intl.getMessage(
                SELLING_ACCOUNT_EMPTY_STATE_MESSAGE_TRANSLATION_KEY,
              )
            )
          }
        />
      ) : (
        <SellingAccountsTiles
          pageId={pageId}
          countryList={countryList}
          sellingAccounts={sellingAccounts}
        />
      )}
    </SellingAccountsWrapper>
  );
};

export default SellingAccounts;

import {
  ChromeConfig,
  ChromeHeaderConfig,
  ChromeHeaderButtonConfig,
  ChromeMenuItemConfig,
  CreateHeaderAttributesParams,
  MenuItemsConfig,
  PageLayoutConfig,
  ChromeObjectInfo,
  ChromeBreadcrumbItemConfig,
} from './types';
import {
  ChromeConfiguration,
  getChromeConfiguration,
  setChromeConfiguration,
  Menu,
  MenuItem,
  BreadcrumbItem,
  PageHeader,
  getPivotEventTarget,
  KeyValue,
  ItemList,
  ObjectInfo,
  Flag,
  Button,
  getActionEventTarget,
  getCommonEventTarget,
  getSubsectionsEventTarget,
  MarketplaceSwitcher,
} from '@amzn/aac-chrome-api';
import { PageLayoutType } from '@chrome/enums';
import { setPageTitle } from '@utils/global';
import * as constants from './constants';

/**
 Run the init process and set the Chrome header, side navigation, page layout
 */
export function initChrome({
  header,
  sideNavigation,
  breadcrumbs,
  layout,
  enableMarketplaceSwitcher = true,
}: Partial<ChromeConfig> = {}) {
  setHeader(header);
  setPageTitle(header?.pageName);
  // Create the breadcrumbs
  breadcrumbs &&
    Array.isArray(breadcrumbs) &&
    breadcrumbs.length > 0 &&
    setBreadcrumbs(breadcrumbs);
  // Create the sideNavigation
  sideNavigation && setSideNavigation(sideNavigation);
  // Set the layout
  layout && setPageLayout(layout);

  // marketplaceSwitcher is enabled by default, this will override the default behavior
  if (!enableMarketplaceSwitcher) {
    setMarketplaceSwitcher(enableMarketplaceSwitcher);
  }
}

/**
 * Reset the current chrome configuration
 */
export function resetChrome() {
  const config = new ChromeConfiguration();
  const pageHeader = new PageHeader();
  const pivot = new Menu();
  config.setPivotNav(pivot);
  config.setPageHeader(pageHeader);
  setChromeConfiguration(config);
  setPageTitle();
}

export function setPageName(pageName: string) {
  try {
    const config = getChromeConfiguration();
    const pageHeader = config.pageHeader ? config.pageHeader : new PageHeader();
    pageHeader.setPageName(pageName);
    config.setPageHeader(pageHeader);
    setChromeConfiguration(config);
    setPageTitle(pageName);
  } catch (err) {
    throw new Error(`${constants.UPDATE_PAGE_NAME_ERROR}${err}`);
  }
}

/**
 Setting the chrome header components, Header attributes / Action buttons
 */
export function setHeader({
  pageName = '',
  type = '',
  name = '',
  key = '',
  value = '',
  buttons,
  subsections,
}: Partial<ChromeHeaderConfig> = {}) {
  try {
    const config = getChromeConfiguration() || new ChromeConfiguration();
    const pageHeader = config.pageHeader || new PageHeader();
    const objectInfo = pageHeader.objectInfo || new ObjectInfo();

    if (value && name) {
      createHeaderAttributes({
        objectInfo,
        type,
        name,
        key,
        value,
      });
    }

    buttons && setHeaderButtons(buttons, objectInfo);
    pageName && pageHeader.setPageName(pageName);
    subsections && pageHeader.setSubsections(setSubSections(subsections));
    pageHeader.setObjectInfo(objectInfo);
    config.setPageHeader(pageHeader);
    setChromeConfiguration(config);
  } catch (err) {
    throw new Error(`${constants.CHROME_HEADER_ERROR}${err}`);
  }
}

export function updateHeaderButtons(
  updatedButtons: Map<string, ChromeHeaderButtonConfig>,
) {
  try {
    const config = getChromeConfiguration();
    const { pageHeader } = config;
    // Update the buttons configuration with the new configuration
    pageHeader.setObjectInfo(
      setHeaderButtons(updatedButtons, pageHeader.objectInfo),
    );
    config.setPageHeader(pageHeader);
    setChromeConfiguration(config);
  } catch (err) {
    throw new Error(`${constants.UPDATE_HEADER_BUTTONS_ERROR}${err}`);
  }
}

/**
 Create the chrome header attributes
 */
function createHeaderAttributes({
  objectInfo,
  type,
  name,
  key,
  value,
}: CreateHeaderAttributesParams) {
  try {
    const attribute = new KeyValue();
    const attributeList = new ItemList();
    type && objectInfo.setObjectType(type);
    name && objectInfo.setObjectName(name);
    attribute.setKey(key);
    attribute.setValue(value);
    attributeList.addItem(attribute);
    objectInfo.setAttributes(attributeList);
    return objectInfo;
  } catch (err) {
    throw new Error(`${constants.HEADER_ATTRIBUTES_ERROR} ${err}`);
  }
}

/**
 Creating the chrome header buttons
 */
function setHeaderButtons(
  buttons: Map<string, ChromeHeaderButtonConfig>,
  objectInfo: ChromeObjectInfo,
) {
  try {
    const actionList = new ItemList();

    Array.from(buttons.values()).forEach((buttonConfig) => {
      const button = createButton(buttonConfig);
      actionList.addItem(button);
    });

    objectInfo.setActions(actionList);
    return objectInfo;
  } catch (err) {
    throw new Error(`${constants.HEADER_BUTTONS_ERROR} ${err}`);
  }
}

function createBreadcrumb({
  label,
  url,
  objectType,
}: ChromeBreadcrumbItemConfig) {
  const breadcrumbItem = new BreadcrumbItem();
  breadcrumbItem.setLabel(label);
  url && breadcrumbItem.setUrl(url);
  objectType && breadcrumbItem.setObjectType(objectType);
  return breadcrumbItem;
}

/**
 Create chrome breadcrumbs
 */
export function setBreadcrumbs(
  breadcrumbsConfig: ChromeBreadcrumbItemConfig[],
) {
  const config = getChromeConfiguration();
  const breadcrumbs: unknown[] = [];
  try {
    breadcrumbsConfig.forEach((breadcrumb) => {
      const breadcrumbItem = createBreadcrumb(breadcrumb);
      breadcrumbs.push(breadcrumbItem);
    });
    config.setBreadcrumbs(breadcrumbs);
    setChromeConfiguration(config);
  } catch (err) {
    throw new Error(`${constants.BREADCRUMBS_ERROR} ${err}`);
  }
}

function createButton(buttonConfig: ChromeHeaderButtonConfig) {
  const { id, label, type, state, callback } = buttonConfig;
  const button = new Button(id, label, type, state);
  if (callback) {
    const actionEventTarget = getActionEventTarget();
    actionEventTarget.addEventListener(buttonConfig.id, buttonConfig.callback);
  }
  return button;
}

function setSubSections({ items = [], selected }: MenuItemsConfig) {
  try {
    const subsections = createMenuItems(items, getSubsectionsEventTarget());
    subsections.setSelectedItemId(selected);
    return subsections;
  } catch (err) {
    throw new Error(`${constants.SUBSECTION_ERROR} ${err}`);
  }
}

/**
 Setting the chrome side navigation
 */
function setSideNavigation({ items = [], selected = null }: MenuItemsConfig) {
  try {
    const config = getChromeConfiguration();
    const pivot = createMenuItems(items, getPivotEventTarget());
    selected && pivot.setSelectedItemId(selected);
    config.setPivotNav(pivot);
    setChromeConfiguration(config);
  } catch (err) {
    throw new Error(`${constants.CHROME_NAVIGATION_ERROR} ${err}`);
  }
}

function createMenuItems(
  items: Array<ChromeMenuItemConfig>,
  eventTarget: EventTarget,
) {
  const menu = new Menu();
  items.forEach(({ id, label, callback, hide, tag }) => {
    if (!hide) {
      const menuItem = new MenuItem(id, label);
      if (tag) {
        const flag = new Flag(tag);
        menuItem.setFlag(flag);
      }
      callback && eventTarget.addEventListener(id, callback);
      menu.addItem(menuItem);
    }
  });
  return menu;
}

function setPageLayout({
  pageLayout = PageLayoutType.Standard,
  closeButtonCallback,
}: PageLayoutConfig) {
  try {
    const config = getChromeConfiguration();
    if (closeButtonCallback) {
      const commonEventTarget = getCommonEventTarget();
      commonEventTarget.addEventListener(
        constants.EXIT_FOCUS_VIEW,
        closeButtonCallback,
      );
    }
    config.setPageLayoutType(pageLayout);
    setChromeConfiguration(config);
  } catch (err) {
    throw new Error(`${constants.PAGE_LAYOUT_ERROR} ${err}`);
  }
}

function setMarketplaceSwitcher(enableMarketplaceSwitcher: boolean) {
  const config = getChromeConfiguration();
  const marketplaceSwitcher = new MarketplaceSwitcher();
  marketplaceSwitcher.setUseMarketplaceSwitcher(enableMarketplaceSwitcher);
  config.setMarketplaceSwitcher(marketplaceSwitcher);
  setChromeConfiguration(config);
}

export function removeActionEventListeners(
  items: ChromeHeaderButtonConfig[] = [],
) {
  removeEventListeners(getActionEventTarget(), items);
}

export function removePivotEventListeners(items: ChromeMenuItemConfig[] = []) {
  removeEventListeners(getPivotEventTarget(), items);
}

export function removeSubsectionEventListeners(
  items: ChromeMenuItemConfig[] = [],
) {
  removeEventListeners(getSubsectionsEventTarget(), items);
}

export function removeCommonEventListeners(
  callback: EventListenerOrEventListenerObject,
) {
  if (callback) {
    const commonEventTarget = getCommonEventTarget();
    commonEventTarget.removeEventListener(constants.EXIT_FOCUS_VIEW, callback);
  }
}

function removeEventListeners(
  eventTarget: EventTarget,
  items: ChromeMenuItemConfig[] | ChromeHeaderButtonConfig[] = [],
) {
  items.forEach(({ id, callback }) => {
    if (id && callback) {
      eventTarget.removeEventListener(id, callback);
    }
  });
}

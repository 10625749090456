/**
 * Temporary fix to standardize state names for compatibility with the address service.
 */
export const getValidatedState = (stateName: string): string => {
  switch (stateName) {
    case 'Washington':
    case 'Washington DC':
      return 'DC';
    default:
      return stateName;
  }
};

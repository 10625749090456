/**
 * Register with business email translation keys
 */
export const REGISTER_WITH_BUSINESS_EMAIL_CARD_TITLE_TRANSLATION_KEY =
  'registration_register_with_business_email_title';
export const REGISTER_WITH_BUSINESS_EMAIL_CARD_TEXT_TRANSLATION_KEY =
  'registration_register_with_business_email_text';
export const REGISTER_WITH_BUSINESS_EMAIL_CARD_SIGNED_IN_TEXT_TRANSLATION_KEY =
  'registration_register_with_business_email_signed_in_text';
export const REGISTER_WITH_BUSINESS_EMAIL_SWITCH_ACCOUNT_BUTTON_TRANSLATION_KEY =
  'registration_register_with_business_email_switch_account_button';
export const REGISTER_WITH_BUSINESS_EMAIL_CONTINUE_BUTTON_TRANSLATION_KEY =
  'registration_register_with_business_email_continue_button';

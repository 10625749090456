import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Icon, Text, Tooltip } from '@amzn/storm-ui';
import { CountryList } from '@amzn/storm-ui-country-selector';
import { angleDown } from '@amzn/storm-ui-icons';
import useIntl from '@i18n/useIntl';
import ReadOnlyFormField from '@components/form/ReadOnlyFormRow';
import { toggleAccountInfoEditMode } from 'src/shared/state/account-settings-slice';
import { FORM_EDIT_BUTTON_TRANSLATION_KEY } from 'src/pages/accountSettings/constants';
import {
  ACCOUNT_COUNTRY_CODES_FIELD_VIEW_LABEL,
  ACCOUNT_NAME_FIELD_VIEW_LABEL,
  ACCOUNT_COUNTRIES,
  REGION_COUNTRY_DISPLAY,
} from 'src/pages/accountSettings/components/AccountDetails/constants';
import * as accountInfoConstants from '@features/account-settings/constants';
import {
  ActionsButtonsWrapper,
  ControllersWrapper,
  ViewWrapper,
} from '@components/form/style';

const AccountDetailsViewer = () => {
  const dispatch = useDispatch();
  const { intl, locale } = useIntl();
  const { getValues } = useFormContext();

  return (
    <ViewWrapper>
      <ControllersWrapper>
        <ReadOnlyFormField
          label={intl.getMessage(ACCOUNT_NAME_FIELD_VIEW_LABEL)}
          id={accountInfoConstants.ACCOUNT_NAME_FIELD_ID}
          value={getValues(
            accountInfoConstants.ACCOUNT_NAME_FIELD_CONTROLLER_NAME,
          )}
        />
        <ReadOnlyFormField
          label={intl.getMessage(ACCOUNT_COUNTRY_CODES_FIELD_VIEW_LABEL)}
          id={accountInfoConstants.ACCOUNT_COUNTRY_CODES_FIELD_ID}
        >
          <Tooltip
            trigger={
              <Text textColor={'secondary'}>
                {intl.getMessage(ACCOUNT_COUNTRIES)} <Icon type={angleDown} />
              </Text>
            }
            message={
              <CountryList
                id={REGION_COUNTRY_DISPLAY}
                language={locale}
                countries={getValues(
                  accountInfoConstants.ACCOUNT_COUNTRY_CODES_FIELD_CONTROLLER_NAME,
                )}
              />
            }
          />
        </ReadOnlyFormField>
      </ControllersWrapper>
      <ActionsButtonsWrapper>
        <Button
          primary={false}
          onClick={() => dispatch(toggleAccountInfoEditMode())}
        >
          {intl.getMessage(FORM_EDIT_BUTTON_TRANSLATION_KEY)}
        </Button>
      </ActionsButtonsWrapper>
    </ViewWrapper>
  );
};

export default AccountDetailsViewer;
